<template>
	<v-app>
		<!-- <TopBar /> -->
		<Header />
		<!-- <SideNav /> -->
		<v-main>
			<slot />
		</v-main>
		<!-- <Tabbar /> -->
		<Footer />
	</v-app>
</template>

<script lang="ts" setup>
// import TopBar from "@/components/Navigation/TopBar.vue";
// import SideNav from "@/components/Navigation/SideNav/SideNav.vue";
// import Tabbar from "@/components/Navigation/Tabbar/index.vue";
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer/Footer.vue";
const route = useRoute();
const orgStore = useOrgStore();
const org_slug = ref(route.params.org_slug as string);
await orgStore.setOrganizationProfile(org_slug.value);
</script>
